import * as React from "react"
import Layout from "../../components/layout"
import Star from "../../components/star"
import '../../style.css'

const FactSheet = () => {

    const info={
        title:'The Swift Fact Sheet',
        image:'burst.png',
        page:'factSheet',
        description:'This fact sheet briefly describes the Swift mission, its intruments, and ground system',
        internalText:["This fact sheet briefly describes the Swift mission, its instruments, and ground system. Also included are tables listing the instrument parameters and a listing of the major institutions involved. The fact sheet will be updated as needed. Last update: July 2004."],
        pdf:{
          pdf:'factsheet.pdf',
          accessible:null
        }
    }

  return (
    <Layout>
        <div>
          <h1>{info.title}</h1>
          <Star/>
          <p>{info.internalText}</p>
          {info.pdf.accessible?
          <p>Check out the <a href={`/resourceLinks/${info.pdf.pdf}`} target='_blank' rel="noreferrer">PDF</a> and <a href={`/resourceLinks/${info.pdf.accessible}`} target='_blank' rel="noreferrer">Disability accessible PDF</a></p>
          :
          <p>Check out the <a href={`/resourceLinks/${info.pdf.pdf}`} target='_blank' rel="noreferrer">PDF</a></p>
          }        
        </div>
    </Layout>
  )
}

export default FactSheet